<template>
  <Modal
    id="modal-owner-check"
    :hideFooter="true"
    title=""
    size="lg"
    no-close-on-backdrop
    hide-header
  >
    <div class="mt-2">
      <p class="text-[#232D39] font-bold text-center text-2xl">
        {{ $t("RegisterPage.ChooseVersion") }}
      </p>

      <p class="text-[#232D39] text-center">
        {{ $t("RegisterPage.VersionInfo") }}
      </p>

      <span class="font-bold block">{{ $t("RegisterPage.TwoVersions") }}</span>
      <ul class="list-disc ml-2 mt-0.5">
        <li>{{ $t("RegisterPage.FirstVersion") }}</li>
        <li>{{ $t("RegisterPage.SecondVersion") }}</li>
      </ul>

      <p class="mt-2">
        {{ $t("RegisterPage.VersionExtraInfo") }}
      </p>
      <p class="">
        {{ $t("RegisterPage.VersionExtraInfo2") }} {{ existingUserData.old_contract_end_date }},
        {{ $t("RegisterPage.VersionExtraInfo2Extra") }}
      </p>

      <section class="flex justify-center flex-col items-center mb-2">
        <span class="font-bold">{{ $t("RegisterPage.Golfigo") }}</span>
        <p>{{ $t("RegisterPage.CannotChange") }}</p>
        <vSelect
          label="title"
          :options="golfigoOptions"
          :reduce="(option) => option.value"
          :clearable="false"
          class="per-page-selector-send-method d-inline-block w-[200px]"
          v-model="version"
        >
        </vSelect>

        <button
          @click="handleProceed"
          class="mt-8 flex w-1/4 p-1 text-center focus:outline-none bg-[#16149F] text-white whitespace-nowrap rounded-md"
        >
          <span class="w-full flex justify-center" v-if="submitting">
            <b-spinner small type="grow" />
            {{ $t("Message.loading") }}...
          </span>

          <span
            class="whitespace-nowrap text-center w-full text-base"
            v-else
            v-text="$t('RegisterPage.Proceed')"
          />
        </button>
      </section>
    </div>
  </Modal>
</template>

<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  props: {
    modifyGitApiUser: {
      type: Function,
      required: false,
    },
    toggleChooseVersion: {
      type: Function,
      required: false,
    },
    existingUserData: {
      type: Object,
      required: false,
    },
  },
  data() {
    const golfigoOptions = [
      {
        title: this.$t("RegisterPage.ApiV"),
        value: "api_version",
      },
      {
        title: this.$t("RegisterPage.FileV"),
        value: "file_version",
      },
    ];
    return {
      version: "",
      submitting: false,
      existingCustomer: true,
      showModal: true,
      is_git_api_user: false,
      golfigoOptions,
    };
  },
  methods: {
    handleProceed() {
      if (this.version) {
        this.modifyGitApiUser(this.version === "api_version");
        this.toggleChooseVersion();
      }
    },
    popup(message, variant, title, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
          text: message,
        },
      });
    },
  },
  mounted() {
    this.$bvModal.show("modal-owner-check");
  },
};
</script>
